import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'controllers'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Javascript imports
import 'javascripts/admin/turbolinks_prepare_for_cache'
import 'javascripts/shared/youtube'
import 'javascripts/shared/map_init'

import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'

import 'chartkick/chart.js'

ActiveStorage.start()

// Platform requires
require('trix')
require('@rails/actiontext')

hljs.registerLanguage('json', json)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../../images', true)
// const imagePath = (name) => images(name, true)
// $(document).on('turbo:load', (e) => {})

document.addEventListener('turbo:load', (event) => {
  document.querySelectorAll('pre').forEach((block) => {
    hljs.highlightBlock(block)
  })
})
